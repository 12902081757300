import {
	ContactSources,
	EmailEngagementSources,
	FormEngagementSources,
	AdSources,
	MeetingEngagementSources,
	AppTypes,
	Apps,
	ConnectorLiteApps
} from 'utils/constants';

const getDisabledContactSources = (rootGetters, apps) => {
	if (rootGetters['user/isHubspotCrmConnected']) {
		return apps
			.filter(app => app.type === AppTypes.CRM && app.id !== ContactSources.HUBSPOT);
	}

	if (rootGetters['user/isPipedriveConnected']) {
		return apps
			.filter(app => app.type === AppTypes.CRM && app.id !== ContactSources.PIPEDRIVE);
	}

	if (rootGetters['user/isCopperConnected']) {
		return apps
			.filter(app => app.type === AppTypes.CRM && app.id !== ContactSources.COPPER);
	}

	if (rootGetters['user/isAirtableConnected']) {
		return apps
			.filter(app => app.type === AppTypes.CRM && app.id !== ContactSources.AIRTABLE);
	}

	if (rootGetters['user/isSalesforceConnected']) {
		return apps
			.filter(app => app.type === AppTypes.CRM && app.id !== ContactSources.SALESFORCE);
	}

	return [];
};

const getDisabledHubspotSources = (
	apps, connectionContactSource, connectionEngagementSource
) => {
	if (connectionContactSource === ContactSources.HUBSPOT) {
		return apps
			.filter(app => app.id === EmailEngagementSources.HUBSPOT_MARKETING);
	}

	if (connectionEngagementSource === EmailEngagementSources.HUBSPOT_MARKETING) {
		return apps
			.filter(app => app.id === ContactSources.HUBSPOT);
	}

	return [];
};

const getAvailableApps = (rootGetters, state) => state.apps
	.filter(({ id }) =>
		![state.connectionContactSource, state.connectionEngagementSource].includes(id))
	.filter(({ id }) =>
		!(id === ContactSources.PIPEDRIVE && !rootGetters['user/isConnectorEnabledForPipedrive']))
	.filter(({ id }) =>
		!(id === ContactSources.AIRTABLE && !rootGetters['user/isConnectorEnabledForAirtable']))
	.filter(({ id }) =>
		!(id === FormEngagementSources.LINKEDIN && !rootGetters['user/isConnectorEnabledForLinkedin']))
	.filter(({ id }) =>
		!(id === FormEngagementSources.WIX && !rootGetters['user/isConnectorEnabledForWix']))
	.filter(({ id }) =>
		!(id === EmailEngagementSources.KLAVIYO && !rootGetters['user/isConnectorEnabledForKlaviyo']))
	.filter(({ id }) =>
		!(id === EmailEngagementSources.MAILERLITE && !rootGetters['user/isConnectorEnabledForMailerlite']));

const isConnectorLiteApp = (rootGetters, app) => {
	const isConnectorLiteEnabled = rootGetters['user/isConnectorLiteEnabled'];

	if (!isConnectorLiteEnabled) {
		return true;
	}

	return ConnectorLiteApps.includes(app.id);
};

export const getters = {
	availableApps(state, localGetters, rootState, rootGetters) {
		return getAvailableApps(rootGetters, state)
			.filter(app => isConnectorLiteApp(rootGetters, app))
			.filter(({ id }) => !getDisabledContactSources(rootGetters, state.apps)
				.some(disabledContactSource => disabledContactSource.id === id))
			.filter(({ id }) => !getDisabledHubspotSources(
				state.apps, state.connectionContactSource, state.connectionEngagementSource
			).some(disabledContactSource => disabledContactSource.id === id));
	},

	disabledHubspotSources(state, localGetters, rootState, rootGetters) {
		return getAvailableApps(rootGetters, state)
			.filter(app => isConnectorLiteApp(rootGetters, app))
			.filter(({ id }) => getDisabledHubspotSources(
				state.apps,
				state.connectionContactSource,
				state.connectionEngagementSource
			).some(disabledContactSource => disabledContactSource.id === id))
			.filter(({ id }) => !getDisabledContactSources(rootGetters, state.apps)
				.some(disabledContactSource => disabledContactSource.id === id));
	},

	isConnectionContactSourceAuthenticated(state, localGetters, rootState, rootGetters) {
		if (state.connectionContactSource === ContactSources.HUBSPOT) {
			return rootGetters['user/isHubspotCrmConnected'];
		}

		if (state.connectionContactSource === ContactSources.PIPEDRIVE) {
			return rootGetters['user/isPipedriveConnected'];
		}

		if (state.connectionContactSource === ContactSources.COPPER) {
			return rootGetters['user/isCopperConnected'];
		}

		if (state.connectionContactSource === ContactSources.AIRTABLE) {
			return rootGetters['user/isAirtableConnected'];
		}

		if (state.connectionContactSource === ContactSources.SALESFORCE) {
			return rootGetters['user/isSalesforceConnected'];
		}

		return false;
	},

	isConnectionEngagementSourceAuthenticated(state, localGetters, rootState, rootGetters) {
		if (state.connectionEngagementSource === EmailEngagementSources.MAILCHIMP) {
			return rootGetters['user/isMailchimpConnected'];
		}

		if (state.connectionEngagementSource === EmailEngagementSources.ACTIVECAMPAIGN) {
			return rootGetters['user/isActiveCampaignConnected'];
		}

		if (state.connectionEngagementSource === EmailEngagementSources.SENDINBLUE) {
			return rootGetters['user/isSendinblueConnected'];
		}

		if (state.connectionEngagementSource === EmailEngagementSources.HUBSPOT_MARKETING) {
			return rootGetters['user/isHubspotMktConnected'];
		}

		if (state.connectionEngagementSource === FormEngagementSources.WIX) {
			return rootGetters['user/isWixConnected'];
		}

		if (state.connectionEngagementSource === FormEngagementSources.ELEMENTOR_FORMS) {
			return rootGetters['user/isElementorConnected'];
		}

		if (state.connectionEngagementSource === FormEngagementSources.CONTACT_FORM_7) {
			return rootGetters['user/isContactForm7Connected'];
		}

		if (state.connectionEngagementSource === FormEngagementSources.GRAVITY) {
			return rootGetters['user/isGravityConnected'];
		}

		if (state.connectionEngagementSource === FormEngagementSources.FACEBOOK) {
			return rootGetters['user/isFacebookConnected'];
		}

		if (state.connectionEngagementSource === AdSources.FACEBOOK_ADS) {
			return rootGetters['user/isFacebookAdsConnected'];
		}

		if (state.connectionEngagementSource === MeetingEngagementSources.CALENDLY) {
			return rootGetters['user/isCalendlyConnected'];
		}

		if (state.connectionEngagementSource === FormEngagementSources.LINKEDIN) {
			return rootGetters['user/isLinkedInConnected'];
		}

		if (state.connectionEngagementSource === EmailEngagementSources.KLAVIYO) {
			return rootGetters['user/isKlaviyoConnected'];
		}

		if (state.connectionEngagementSource === EmailEngagementSources.MAILERLITE) {
			return rootGetters['user/isMailerliteConnected'];
		}

		return false;
	},

	appAuthData(state) {
		if (state.appAuthAppId === Apps.COPPER) {
			return {
				email: state.appAuthAccountId,
				apiKey: state.appAuthApiKey
			};
		}

		if (state.appAuthAppId === Apps.ACTIVECAMPAIGN) {
			return {
				accountName: state.appAuthAccountId,
				apiKey: state.appAuthApiKey
			};
		}

		return {};
	},

	isEngagementSyncSupported(state) {
		return state.connectionEngagementSource !== EmailEngagementSources.MAILERLITE;
	}
};
