/* eslint-disable max-lines, max-lines-per-function */
import logger from 'utils/logger';
import { api } from 'api';
import router from 'router';
import ROUTE_NAMES from 'router/route-names';
import SHOW_NOTIFICATION from 'store/modules/notifications/actionTypes';
import {
	EntityTypes, AppLabels,
	ContactSyncDirections,
	CrmSources
} from 'utils/constants';
import { ConnectionTypes, ConnectionStatuses } from 'utils/constants/connections';
import { getInvalidOauthTokenError } from 'utils/oauth';
import {
	OPTIONS,
	FIELD_MAPPINGS_OPTIONS,
	SEGMENT_FIELD_MAPPINGS_OPTIONS,
	CONNECTION,
	CONTACT_SYNC_RULES,
	CONTACT_CREATION_RULE_OPTIONS,
	CONTACT_CREATION_RULES,
	DESTINATION_SEGMENT,
	ENGAGEMENT_EVENT_SEGMENT,
	FIELD_MAPPINGS,
	REVERSED_FIELD_MAPPINGS,
	ENGAGEMENT_SYNC_RULES,
	ENGAGEMENT_SYNC_RULE_OPTIONS,
	ERRORS,
	VIEW,
	STATS,
	FEED,
	CONNECTION_STATUS_PERIOD,
	CONNECTION_STATUS_OPTIONS,
	FEED_PAGE,
	FEED_PAGE_COUNT,
	ENGAGEMENT_SYNC_UPGRADE_DIALOG_VISIBILITY,
	MAP_REQUIRED_FIELDS_DIALOG_VISIBILITY,
	DIALOG_SYNC_RECENT_ENGAGEMENTS_VISIBILITY,
	SET_REFRESH_FIELDS_IN_PROGRESS,
	UNIFIED_FIELD_MAPPINGS
} from './mutationTypes';

import {
	CONFIGURE_VIEW,
	LIST_NAVIGATE,
	OPTIONS_GET,
	OPTIONS_REFRESH,
	FIELD_MAPPINGS_OPTIONS_GET,
	SEGMENT_FIELD_MAPPINGS_OPTIONS_GET,
	SEGMENT_FIELD_MAPPINGS_GET,
	REVERSED_FIELD_MAPPINGS_GET,
	FIELD_MAPPINGS_GET,
	ENGAGEMENT_SEGMENT_FIELD_MAPPINGS_OPTIONS_GET,
	ERRORS_CLEAR,
	CONNECTION_GET,
	CONNECTION_CREATE,
	CONNECTION_DELETE,
	CONNECTION_EDIT,
	CONNECTION_ACTIVATE,
	CONNECTION_REQUEST_SYNC,
	CONNECTION_SYNC_PROGRESS_UPDATE,
	CONNECTION_SETTINGS_UPDATE,
	RULE_CREATE,
	RULE_EDIT,
	RULE_DELETE,
	CONTACT_SYNC_RULES_GET,
	CONTACT_CREATION_RULE_OPTIONS_GET,
	CONTACT_CREATION_RULES_GET,
	CONTACT_CREATION_RULE_CREATE,
	CONTACT_CREATION_RULE_EDIT,
	CONTACT_CREATION_RULE_DELETE,
	MAPPING_CREATE,
	MAPPING_EDIT,
	MAPPING_DELETE,
	ENGAGEMENT_SYNC_RULES_GET,
	ENGAGEMENT_SYNC_RULE_CREATE,
	ENGAGEMENT_SYNC_RULE_DELETE,
	ENGAGEMENT_SYNC_RULE_OPTIONS_GET,
	STATS_GET,
	FEED_GET,
	CONNECTION_STATUS_OPTIONS_GET,
	CONNECTION_STATUS_PERIOD_EDIT,
	ENGAGEMENT_SYNC_UPGRADE_DIALOG_CLOSE,
	ENGAGEMENT_SYNC_UPGRADE_DIALOG_OPEN,
	MAP_REQUIRED_FIELDS_DIALOG_CLOSE,
	MAP_REQUIRED_FIELDS_DIALOG_OPEN,
	DIALOG_SYNC_RECENT_ENGAGEMENTS_OPEN,
	DIALOG_SYNC_RECENT_ENGAGEMENTS_CLOSE,
	REVERSED_CONTACT_SYNC_RULE_EDIT
} from './actionTypes';

import {
	isEngagementSyncTabVisible,
	isFormEngagementSyncVisible,
	isContactSyncTabVisible,
	isContactSyncDirectionTabVisible,
	isEmailEngagementSyncVisible,
	isDealCreationVisible,
	isPreDealCreationSettingVisible,
	isContactCreationVisible,
	isContactEventsComponentVisible,
	isEngagementEventsComponentVisible,
	isFormEventsComponentVisible,
	isMeetingEventsComponentVisible,
	isFormToolRecordEngagementComponentVisible,
	isLeadIdentificationVisible,
	isConnectionStatusSyncTabVisible,
	isContactSyncFieldMappingTabVisible,
	isEngagementSyncSettingsTabVisible
} from './helpers';

import { trackConnectionManualSync } from '../helpers';

/**
 * @param {Object} params
 * @param {String} params.entityType
 * @param {String} params.key
 * @return {String}
 */
const mapKey = ({ entityType, key }) => `${entityType}.${key}`;

const mapMapping = ({ mapping }) => ({
	...mapping,
	sourceEntityField: mapKey({
		entityType: mapping.sourceEntityType,
		key: mapping.sourceField
	}),
	destinationEntityField: mapKey({
		entityType: mapping.destinationEntityType ?? EntityTypes.CONTACT,
		key: mapping.destinationField
	})
});

const isGlobalFieldMappingEnabled = ({ rootGetters }) => rootGetters['user/isGlobalFieldMappingEnabled'];

const createNewUnifiedFieldMapping = ({ mapping, mappingDirection }) => {
	const newUnifiedMapping = {
		direction: mappingDirection,
		isReadOnly: mapping.isReadOnly,
		createdAt: mapping.createdAt,
		displayIndex: mapping.displayIndex,
		isCustomDestinationField: mapping.isCustomDestinationField,
		mappingsGroup: {
			[mappingDirection]: mapping._id
		}
	};

	if (mappingDirection === ContactSyncDirections.CRM_TO_MARKETING) {
		newUnifiedMapping.sourceField = mapping.sourceField;
		newUnifiedMapping.sourceEntityField = mapping.sourceEntityField;
		newUnifiedMapping.sourceEntityType = mapping.sourceEntityType;
		newUnifiedMapping.sourceFieldType = mapping.sourceFieldType;
		newUnifiedMapping.sourceSegmentId = mapping.sourceSegmentId;
		newUnifiedMapping.destinationField = mapping.destinationField;
		newUnifiedMapping.destinationFieldType = mapping.destinationFieldType;
		newUnifiedMapping.destinationEntityField = mapping.destinationEntityField;
		newUnifiedMapping.destinationEntityType = mapping.destinationEntityType;
		newUnifiedMapping.destinationSegmentId = mapping.destinationSegmentId;
	} else {
		newUnifiedMapping.sourceField = mapping.destinationField;
		newUnifiedMapping.sourceEntityField = mapping.destinationEntityField;
		newUnifiedMapping.sourceEntityType = mapping.destinationEntityType;
		newUnifiedMapping.sourceFieldType = mapping.destinationFieldType;
		newUnifiedMapping.sourceSegmentId = mapping.destinationSegmentId;
		newUnifiedMapping.destinationField = mapping.sourceField;
		newUnifiedMapping.destinationFieldType = mapping.sourceFieldType;
		newUnifiedMapping.destinationEntityField = mapping.sourceEntityField;
		newUnifiedMapping.destinationEntityField = mapping.sourceEntityField;
		newUnifiedMapping.destinationSegmentId = mapping.sourceSegmentId;
	}

	return newUnifiedMapping;
};

export const actions = {
	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[CONFIGURE_VIEW]({
		commit, getters, rootGetters, state
	}) {
		try {
			commit(VIEW, {
				view: {
					contactSync: {
						contactSyncTab: isContactSyncTabVisible({ state, getters, rootGetters }),
						contactSyncDirectionTab: isContactSyncDirectionTabVisible({ state }),
						contactSyncRules: true,
						contactCreation: isContactCreationVisible({ getters, rootGetters }),
						mapFields: true,
						fieldMappingTab: isContactSyncFieldMappingTabVisible({ state })
					},
					engagementSync: {
						engagementSyncTab: isEngagementSyncTabVisible({ state, getters, rootGetters }),
						formEngagementSync: {
							formEngagementSyncComponent: isFormEngagementSyncVisible({ getters }),
							recordEngagement: isFormToolRecordEngagementComponentVisible({ getters }),
							identifyLeads: isLeadIdentificationVisible({ getters }),
							preDealCreationSetting: isPreDealCreationSettingVisible({ rootGetters })
						},
						emailEngagementSync: {
							emailEngagementSyncComponent: isEmailEngagementSyncVisible({ getters })
						},
						dealEngagementSync: {
							dealEngagementSyncComponent: isDealCreationVisible({ getters, rootGetters })
						},
						engagementSettingsTab: isEngagementSyncSettingsTabVisible({ state })
					},
					connectionStatus: {
						connectionStatusSyncTab: isConnectionStatusSyncTabVisible({ getters, rootGetters }),
						connectionStatusComponents: {
							contactEventsComponent: isContactEventsComponentVisible({
								state, getters, rootGetters
							}),
							engagementEventsComponent: isEngagementEventsComponentVisible({
								state, getters, rootGetters
							}),
							formEventsComponent: isFormEventsComponentVisible({ getters }),
							meetingEventsComponent: isMeetingEventsComponentVisible({ getters })
						}
					}
				}
			});
		} catch (exception) {
			commit(VIEW, { views: state.views });
			logger.error(exception);
		}
	},

	[LIST_NAVIGATE]: () => {
		router.push({ name: ROUTE_NAMES.CONNECTIONS });
	},

	[OPTIONS_GET]: async ({
		commit,
		state,
		rootState: { token }
	}, { requestedData, setNoCacheHeader = false } = {}) => {
		try {
			const options = await api.connections.options.get({
				token,
				contactSource: state.connection.contactSource,
				engagementSource: state.connection.engagementEventSource,
				requestedData,
				setNoCacheHeader
			});

			commit(OPTIONS, options);
		} catch (exception) {
			const invalidOauthTokenError = getInvalidOauthTokenError({
				exception,
				engagementEventSource: state.connection.engagementEventSource
			});

			if (invalidOauthTokenError) {
				return commit(ERRORS, [invalidOauthTokenError]);
			}

			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[OPTIONS_REFRESH]: async ({
		commit,
		state,
		dispatch
	}) => {
		commit(SET_REFRESH_FIELDS_IN_PROGRESS, true);

		await Promise.all([
			dispatch(OPTIONS_GET, { setNoCacheHeader: true }),
			dispatch(
				ENGAGEMENT_SYNC_RULE_OPTIONS_GET,
				{ connectionId: state.connection._id, setNoCacheHeader: true }
			),
			dispatch(FIELD_MAPPINGS_OPTIONS_GET, { setNoCacheHeader: true })
		]);

		if (state.destinationSegment) {
			dispatch(
				SEGMENT_FIELD_MAPPINGS_OPTIONS_GET,
				{ destinationSegmentId: state.destinationSegment, setNoCacheHeader: true }
			);
		}

		if (state.engagementEventSegment) {
			dispatch(
				ENGAGEMENT_SEGMENT_FIELD_MAPPINGS_OPTIONS_GET,
				{ engagementEventSegmentId: state.engagementEventSegment, setNoCacheHeader: true }
			);
		}

		commit(SET_REFRESH_FIELDS_IN_PROGRESS, false);
	},

	[FIELD_MAPPINGS_OPTIONS_GET]: async ({
		commit,
		state,
		rootState: { token }
	}, { setNoCacheHeader } = {}) => {
		try {
			const options = await api.connections.fieldMappings.options.get({
				token,
				connectionId: state.connection._id,
				setNoCacheHeader
			});

			commit(FIELD_MAPPINGS_OPTIONS, options);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}
		}
	},

	[SEGMENT_FIELD_MAPPINGS_OPTIONS_GET]: async ({
		commit,
		state,
		rootState: { token }
	}, { destinationSegmentId, setNoCacheHeader }) => {
		commit(DESTINATION_SEGMENT, destinationSegmentId);

		try {
			const options = await api.connections.fieldMappings.options.get({
				token,
				connectionId: state.connection._id,
				destinationSegmentId,
				setNoCacheHeader
			});

			options.sourceField.options = options.sourceField.options.map(
				option => ({
					...option,
					id: String(option.id),
					entityKey: mapKey({
						entityType: option.entityType ?? EntityTypes.CONTACT,
						key: option.key
					})
				})
			);

			commit(SEGMENT_FIELD_MAPPINGS_OPTIONS, options);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}
		}
	},

	[ENGAGEMENT_SEGMENT_FIELD_MAPPINGS_OPTIONS_GET]: async ({
		commit,
		state,
		rootState: { token }
	}, { engagementEventSegmentId, setNoCacheHeader }) => {
		commit(ENGAGEMENT_EVENT_SEGMENT, engagementEventSegmentId);

		try {
			const options = await api.connections.fieldMappings.options.get({
				token,
				connectionId: state.connection._id,
				engagementEventSegmentId,
				setNoCacheHeader
			});

			options.sourceField.options = options.sourceField.options.map(
				// eslint-disable-next-line sonarjs/no-identical-functions
				option => ({
					...option,
					id: String(option.id),
					entityKey: mapKey({
						entityType: option.entityType ?? EntityTypes.CONTACT,
						key: option.key
					})
				})
			);

			commit(SEGMENT_FIELD_MAPPINGS_OPTIONS, options);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}
		}
	},

	[FIELD_MAPPINGS_GET]: async ({
		commit,
		state,
		rootGetters,
		rootState: { token }
	}) => {
		const destinationSegmentIds = state.contactSyncRules.map(rule => {
			const isGlobalFieldMappingSupported = state.options.isGlobalFieldMappingSupported
				&& isGlobalFieldMappingEnabled({ rootGetters });

			if (isGlobalFieldMappingSupported) {
				return 'global';
			}

			return rule.destinationSegmentId;
		});

		const fieldMappingsRaw = await Promise.all(
			destinationSegmentIds.map(async destinationSegmentId => api.connections.fieldMappings.get({
				token,
				connectionId: state.connection._id,
				destinationSegmentId
			}))
		);

		const fieldMappings = fieldMappingsRaw
			.flat()
			.map(
				mapping => mapMapping({ mapping })
			);

		// store field mappings so they can be used for required field check
		commit(FIELD_MAPPINGS, fieldMappings);

		const unifiedMappings = [];

		fieldMappings.forEach(mapping => {
			let mappingDirection = ContactSyncDirections.CRM_TO_MARKETING;

			if (!CrmSources.includes(mapping.source)) {
				mappingDirection = ContactSyncDirections.MARKETING_TO_CRM;
			}

			const newUnifiedMapping = createNewUnifiedFieldMapping({ mapping, mappingDirection });

			const existsingMapping = unifiedMappings.find(
				unifiedMapping => {
					const newMappingKey = `${newUnifiedMapping.sourceEntityField?.toLocaleLowerCase()}_${newUnifiedMapping.destinationEntityField.toLocaleLowerCase()}`;
					const existingMappingKey = `${unifiedMapping.sourceEntityField.toLocaleLowerCase()}_${unifiedMapping.destinationEntityField.toLocaleLowerCase()}`;

					return newMappingKey === existingMappingKey;
				}
			);

			if (!existsingMapping) {
				unifiedMappings.push(newUnifiedMapping);
			} else {
				existsingMapping.mappingsGroup[mappingDirection] = mapping._id;
				if (!existsingMapping.sourceSegmentId) {
					existsingMapping.sourceSegmentId = mapping.sourceSegmentId;
				}

				if (!existsingMapping.destinationSegmentId) {
					existsingMapping.destinationSegmentId = mapping.destinationSegmentId;
				}

				if (!existsingMapping.createdAt || existsingMapping.createdAt > mapping.createdAt) {
					existsingMapping.createdAt = mapping.createdAt;
				}

				const isOppositeFieldMapping = existsingMapping.direction !== mappingDirection
					&& Object.values(ContactSyncDirections).includes(mappingDirection);

				if (isOppositeFieldMapping) {
					existsingMapping.direction = ContactSyncDirections.TWO_WAY;
				}

				if (mapping.displayIndex < existsingMapping.displayIndex) {
					existsingMapping.displayIndex = mapping.displayIndex;
				}
			}
		});

		unifiedMappings.sort((mappingA, mappingB) => {
			if (!mappingA.displayIndex && mappingB.displayIndex) {
				return -1;
			}

			if (mappingA.displayIndex < mappingB.displayIndex) {
				return -1;
			}

			if (mappingA.displayIndex === mappingB.displayIndex) {
				if (!mappingA.createdAt && mappingB.createdAt) {
					return -1;
				}

				if (mappingA.createdAt < mappingB.createdAt) {
					return -1;
				}

				return 1;
			}

			return 1;
		});

		commit(UNIFIED_FIELD_MAPPINGS, unifiedMappings);
	},

	[SEGMENT_FIELD_MAPPINGS_GET]: async ({
		commit,
		state,
		rootState: { token }
	}, { destinationSegmentId }) => {
		try {
			let fieldMappings = await api.connections.fieldMappings.get({
				token,
				connectionId: state.connection._id,
				destinationSegmentId
			});

			fieldMappings.sort((mappingA, mappingB) => mappingA._id > mappingB._id);

			fieldMappings = fieldMappings.map(
				mapping => mapMapping({ mapping })
			);

			commit(FIELD_MAPPINGS, fieldMappings);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}
		}
	},

	[REVERSED_FIELD_MAPPINGS_GET]: async ({
		commit,
		dispatch,
		state,
		rootState: { token }
	}) => {
		if (!state.contactSyncRules.length) {
			await dispatch(CONTACT_SYNC_RULES_GET, state.connection._id);
		}

		const reversedContactSyncRule = state.contactSyncRules.find(
			rule => rule.source === state.connection.engagementEventSource
		);

		if (!reversedContactSyncRule) {
			return;
		}

		try {
			const reversedFieldMappings = await api.connections.fieldMappings.get({
				token,
				connectionId: state.connection._id,
				destinationSegmentId: 'global',
				source: state.connection.engagementEventSource,
				destination: state.connection.contactSource
			});

			commit(REVERSED_FIELD_MAPPINGS, reversedFieldMappings);
		} catch (exception) {
			if (exception.errors) {
				commit(ERRORS, exception.errors);
			}
		}
	},

	[CONNECTION_GET]: async ({
		commit,
		rootState: { token }
	}, id) => {
		try {
			const connection = await api.connections.get({ id, token });

			commit(CONNECTION, connection);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[CONNECTION_CREATE]: async ({
		commit,
		state,
		rootState: { token }
	}) => {
		try {
			const connectionData = {
				contactSource: state.connection.contactSource,
				engagementSource: state.connection.engagementEventSource,
				type: ConnectionTypes.ENGAGEMENT_SYNC
			};

			const connection = await api.connections.post({ token, connectionData });

			commit(CONNECTION, connection);

			router.push({
				name: ROUTE_NAMES.CONNECTIONS_EDIT_ENGAGEMENT_SYNC,
				params: {
					id: connection._id
				}
			});
		} catch (exception) {
			if (exception.errors) {
				commit(ERRORS, exception.errors);

				return;
			}

			logger.error(exception);
		}
	},

	[CONNECTION_DELETE]: async ({
		commit,
		dispatch,
		state,
		rootState: { token }
	}) => {
		try {
			await api.connections.delete({ connectionId: state.connection._id, token });

			router.push({
				name: ROUTE_NAMES.CONNECTIONS
			});

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Connection deleted',
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[CONNECTION_EDIT]: async ({
		state,
		commit,
		dispatch,
		rootState: { token }
	}, data) => {
		try {
			const connection = await api.connections.patch({
				connectionId: state.connection._id,
				token,
				connectionData: data
			});

			commit(CONNECTION, connection);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Connection updated',
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[CONNECTION_ACTIVATE]: async ({
		state,
		commit,
		dispatch,
		rootState: { token },
		rootGetters
	}, isRecentEngagementSyncEnabled) => {
		const connectionData = {
			status: 'active'
		};

		const notification = {
			message: 'Connection activated. Waiting for new events'
		};

		if (isRecentEngagementSyncEnabled) {
			connectionData.includeRecentEngagements = true;

			notification.message = 'Connection activated. Sync starts within 5 minutes';
		}

		try {
			await api.connections.patch({
				connectionId: state.connection._id,
				token,
				connectionData
			});

			await dispatch(CONNECTION_GET, state.connection._id);

			const isConnectorV3Enabled = rootGetters['user/isConnectorV3Enabled'];

			if (isConnectorV3Enabled && state.connection.type === ConnectionTypes.CONTACT_SYNC) {
				router.push({
					name: ROUTE_NAMES.CONNECTIONS_CONTACT_SYNC_STATUS,
					params: {
						id: state.connection._id
					}
				});
			} else if (isConnectorV3Enabled && state.connection.type === ConnectionTypes.FORM_SYNC) {
				router.push({
					name: ROUTE_NAMES.CONNECTIONS_FORM_STATUS,
					params: {
						id: state.connection._id
					}
				});
			} else {
				router.push({
					name: ROUTE_NAMES.CONNECTIONS
				});
			}

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				notification.message,
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[CONNECTION_REQUEST_SYNC]: async ({
		rootState: { token },
		rootGetters,
		state,
		commit,
		dispatch
	}) => {
		const connectionData = {
			status: ConnectionStatuses.SYNCING
		};

		try {
			await api.connections.patch({
				connectionId: state.connection._id,
				token,
				connectionData
			});

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Sync started and will complete within 5 minutes',
				{ root: true }
			);

			trackConnectionManualSync(state.connection, 'edit');

			if (rootGetters['user/isConnectorV3Enabled'] && state.connection.type === ConnectionTypes.CONTACT_SYNC) {
				router.push({
					name: ROUTE_NAMES.CONNECTIONS_CONTACT_SYNC_STATUS,
					params: {
						id: state.connection._id
					}
				});

				return;
			}

			router.push({
				name: ROUTE_NAMES.CONNECTIONS
			});
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}
		}
	},

	[CONNECTION_SYNC_PROGRESS_UPDATE]: async ({
		state,
		commit
	}) => {
		commit(CONNECTION, {
			...state.connection,
			progressUpdatedAt: new Date()
		});
	},

	[RULE_CREATE]: async ({
		commit,
		state,
		rootState: { token },
		dispatch
	}, { ruleData }) => {
		try {
			const isOverwritingRuleSaved = state.contactSyncRules?.some(
				rule => rule.isOverwriting
			);

			let isOverwriting = false;

			// if overwriting rule exists new rule must be not overwriting
			if (!isOverwritingRuleSaved) {
				isOverwriting = ruleData.source === state?.connection?.contactSource;
			}

			await api.connections.contactSyncRules.post({
				connectionId: state.connection._id,
				token,
				ruleData: { ...ruleData, isOverwriting }
			});

			dispatch(CONTACT_SYNC_RULES_GET, state.connection._id);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'New contact sync rule added',
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[RULE_DELETE]: async ({
		commit,
		state,
		rootState: { token },
		dispatch
	}, { contactSyncRuleId }) => {
		try {
			await api.connections.contactSyncRules.delete({
				connectionId: state.connection._id,
				contactSyncRuleId,
				token
			});

			dispatch(CONTACT_SYNC_RULES_GET, state.connection._id);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Contact sync rule deleted',
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[RULE_EDIT]: async ({
		commit,
		dispatch,
		state,
		rootState: { token }
	}, { contactSyncRuleId, ruleData }) => {
		try {
			await api.connections.contactSyncRules.patch({
				connectionId: state.connection._id,
				contactSyncRuleId,
				token,
				ruleData
			});

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Contact sync rule updated',
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[CONTACT_SYNC_RULES_GET]: async ({
		commit,
		rootState: { token }
	}, connectionId) => {
		try {
			const contactSyncRules = await api.connections.contactSyncRules.get({
				connectionId,
				token
			});

			commit(CONTACT_SYNC_RULES, contactSyncRules);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[CONTACT_CREATION_RULE_OPTIONS_GET]: async ({
		commit,
		rootState: { token }
	}, { connectionId, setNoCacheHeader }) => {
		try {
			const contactCreationRuleOptions = await api.connections.contactCreationRules.options.get({
				connectionId,
				setNoCacheHeader,
				token
			});

			commit(CONTACT_CREATION_RULE_OPTIONS, contactCreationRuleOptions);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[CONTACT_CREATION_RULES_GET]: async ({
		commit,
		rootState: { token }
	}, { connectionId }) => {
		try {
			const contactCreationRules = await api.connections.contactCreationRules.get({
				connectionId,
				token
			});

			commit(CONTACT_CREATION_RULES, contactCreationRules);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[CONTACT_CREATION_RULE_CREATE]: async ({
		commit,
		state,
		rootState: { token },
		dispatch
	}, { ruleData }) => {
		try {
			await api.connections.contactCreationRules.post({
				connectionId: state.connection._id,
				token,
				ruleData
			});

			dispatch(CONTACT_CREATION_RULES_GET, { connectionId: state.connection._id });

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'New contact creation rule added',
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[CONTACT_CREATION_RULE_EDIT]: async ({
		commit,
		state,
		dispatch,
		rootState: { token }
	}, { contactCreationRuleId, ruleData }) => {
		try {
			await api.connections.contactCreationRules.patch({
				connectionId: state.connection._id,
				contactCreationRuleId,
				token,
				ruleData
			});

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Contact creation rule updated',
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[CONTACT_CREATION_RULE_DELETE]: async ({
		commit,
		state,
		dispatch,
		rootState: { token }
	}, { contactCreationRuleId }) => {
		try {
			await api.connections.contactCreationRules.delete({
				connectionId: state.connection._id,
				contactCreationRuleId,
				token
			});

			await dispatch(CONTACT_CREATION_RULES_GET, { connectionId: state.connection._id });

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Contact creation rule deleted',
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[ENGAGEMENT_SYNC_RULE_OPTIONS_GET]: async ({
		commit,
		rootState: { token }
	}, { connectionId, setNoCacheHeader } = {}) => {
		try {
			const engagementSyncRuleOptions = await api.connections.engagementSyncRules.options.get({
				connectionId,
				setNoCacheHeader,
				token
			});

			commit(ENGAGEMENT_SYNC_RULE_OPTIONS, engagementSyncRuleOptions);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[ENGAGEMENT_SYNC_RULES_GET]: async ({
		commit,
		rootState: { token }
	}, connectionId) => {
		try {
			const engagementSyncRules = await api.connections.engagementSyncRules.get({
				connectionId,
				token
			});

			commit(ENGAGEMENT_SYNC_RULES, engagementSyncRules);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[ENGAGEMENT_SYNC_RULE_CREATE]: async ({
		commit,
		state,
		rootState: { token },
		dispatch
	}, data) => {
		try {
			await api.connections.engagementSyncRules.put({
				connectionId: state.connection._id,
				token,
				data
			});

			dispatch(ENGAGEMENT_SYNC_RULES_GET, state.connection._id);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Engagement sync rule enabled',
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[ENGAGEMENT_SYNC_RULE_DELETE]: async ({
		commit,
		state,
		rootState: { token },
		dispatch
	}, id) => {
		try {
			await api.connections.engagementSyncRules.delete({
				connectionId: state.connection._id,
				id,
				token
			});

			dispatch(ENGAGEMENT_SYNC_RULES_GET, state.connection._id);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Engagement sync rule disabled',
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[CONNECTION_SETTINGS_UPDATE]: async ({
		commit,
		state,
		rootState: { token },
		dispatch
	}, settings) => {
		const data = {
			settings
		};

		try {
			await api.connections.settings.put({
				connectionId: state.connection._id,
				token,
				data
			});

			await dispatch(CONNECTION_GET, state.connection._id);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Connection settings updated',
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[MAPPING_CREATE]: async ({
		commit,
		dispatch,
		state,
		rootState: { token }
	}, { mappingData, isReversed, isUnifiedFieldMapping }) => {
		try {
			// eslint-disable-next-line fp/no-rest-parameters
			const { group, ...fieldMappingData } = mappingData;

			await api.connections.fieldMappings.post({
				connectionId: state.connection._id,
				token,
				fieldMappingData: {
					destinationSegmentId: group,
					...fieldMappingData
				}
			});

			if (isReversed) {
				dispatch(REVERSED_FIELD_MAPPINGS_GET);
			} else if (isUnifiedFieldMapping) {
				dispatch(FIELD_MAPPINGS_GET);
			} else {
				dispatch(
					SEGMENT_FIELD_MAPPINGS_GET,
					{
						destinationSegmentId: group,
						source: fieldMappingData.source,
						destination: fieldMappingData.destination
					}
				);
			}

			dispatch(`notifications/${SHOW_NOTIFICATION}`, 'New field mapping created', { root: true });
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[MAPPING_EDIT]: async ({
		commit,
		dispatch,
		state,
		rootState: { token }
	}, {
		mappingId,
		mappingData,
		isReversed,
		isUnifiedFieldMapping
	}) => {
		try {
			// eslint-disable-next-line fp/no-rest-parameters
			const { group, ...fieldMappingData } = mappingData;

			await api.connections.fieldMappings.patch({
				connectionId: state.connection._id,
				fieldMappingId: mappingId,
				token,
				fieldMappingData: {
					destinationSegmentId: group,
					...fieldMappingData
				}
			});

			if (isReversed) {
				dispatch(REVERSED_FIELD_MAPPINGS_GET);
			} else if (isUnifiedFieldMapping) {
				dispatch(FIELD_MAPPINGS_GET);
			} else {
				dispatch(
					SEGMENT_FIELD_MAPPINGS_GET,
					{
						destinationSegmentId: group,
						source: fieldMappingData.source,
						destination: fieldMappingData.destination
					}
				);
			}

			dispatch(`notifications/${SHOW_NOTIFICATION}`, 'Field mapping updated', { root: true });
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}
		}
	},

	[MAPPING_DELETE]: async ({
		commit,
		dispatch,
		state,
		rootState: { token }
	}, {
		mappingId,
		group,
		isReversed = false,
		source,
		destination,
		isUnifiedFieldMapping
	}) => {
		try {
			await api.connections.fieldMappings.delete({
				connectionId: state.connection._id,
				fieldMappingId: mappingId,
				token
			});

			if (isReversed) {
				dispatch(REVERSED_FIELD_MAPPINGS_GET);
				dispatch(`notifications/${SHOW_NOTIFICATION}`, 'Field mapping deleted', { root: true });
			} else if (isUnifiedFieldMapping) {
				dispatch(FIELD_MAPPINGS_GET);
				dispatch(`notifications/${SHOW_NOTIFICATION}`, 'Field mapping updated', { root: true });
			} else {
				dispatch(
					SEGMENT_FIELD_MAPPINGS_GET,
					{
						destinationSegmentId: group,
						source,
						destination
					}
				);
				dispatch(`notifications/${SHOW_NOTIFICATION}`, 'Field mapping deleted', { root: true });
			}
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[STATS_GET]: async ({
		commit,
		state,
		rootState: { token }
	}) => {
		try {
			const stats = await api.connections.stats.get({
				connectionId: state.connection._id,
				period: state.period,
				token
			});

			commit(STATS, stats);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[FEED_GET]: async ({
		commit,
		state,
		rootState: { token }
	}, page) => {
		if (page) {
			commit(FEED_PAGE, page);
		}

		try {
			const { data, pagination } = await api.connections.feed.get({
				connectionId: state.connection._id,
				page,
				token
			});

			commit(FEED, data);
			commit(FEED_PAGE_COUNT, pagination.pageCount);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[CONNECTION_STATUS_OPTIONS_GET]: async ({
		rootState: { token },
		state,
		commit
	}) => {
		try {
			const connectionStatusOptions = await api.connections.connectionStatus.options.get({
				connectionId: state.connection._id,
				token
			});

			commit(CONNECTION_STATUS_OPTIONS, connectionStatusOptions);

			const { period } = connectionStatusOptions;

			if (period && period.default) {
				commit(CONNECTION_STATUS_PERIOD, period.default);
			}
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[CONNECTION_STATUS_PERIOD_EDIT]: async ({ commit, dispatch }, period) => {
		try {
			commit(CONNECTION_STATUS_PERIOD, period);

			dispatch(STATS_GET);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[MAP_REQUIRED_FIELDS_DIALOG_OPEN]({ commit }) {
		commit(MAP_REQUIRED_FIELDS_DIALOG_VISIBILITY, true);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[MAP_REQUIRED_FIELDS_DIALOG_CLOSE]({ commit }) {
		commit(MAP_REQUIRED_FIELDS_DIALOG_VISIBILITY, false);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[ENGAGEMENT_SYNC_UPGRADE_DIALOG_OPEN]({ commit }) {
		commit(ENGAGEMENT_SYNC_UPGRADE_DIALOG_VISIBILITY, true);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[ENGAGEMENT_SYNC_UPGRADE_DIALOG_CLOSE]({ commit }) {
		commit(ENGAGEMENT_SYNC_UPGRADE_DIALOG_VISIBILITY, false);
	},

	[DIALOG_SYNC_RECENT_ENGAGEMENTS_OPEN]({ commit }) {
		commit(DIALOG_SYNC_RECENT_ENGAGEMENTS_VISIBILITY, true);
	},

	[DIALOG_SYNC_RECENT_ENGAGEMENTS_CLOSE]({ commit }) {
		commit(DIALOG_SYNC_RECENT_ENGAGEMENTS_VISIBILITY, false);
	},

	[REVERSED_CONTACT_SYNC_RULE_EDIT]: async ({
		rootState: { token },
		commit,
		state,
		dispatch
	}, segmentId) => {
		const sourceSegment = state.options.secondaryContactSourceSegments
			.find(({ _id }) => _id === segmentId);

		const ruleData = {
			source: state.connection.engagementEventSource,
			sourceSegmentId: segmentId,
			sourceSegmentName: sourceSegment.name,
			destination: state.connection.contactSource,
			destinationSegmentId: 'global',
			destinationSegmentName: `${AppLabels[state.connection.contactSource]} database`
		};

		const reversedRule = state.contactSyncRules.find(
			rule => rule.source === state.connection.engagementEventSource
		);

		if (reversedRule) {
			try {
				await api.connections.contactSyncRules.patch({
					connectionId: state.connection._id,
					contactSyncRuleId: reversedRule._id,
					token,
					ruleData
				});

				dispatch(
					`notifications/${SHOW_NOTIFICATION}`,
					'Contact sync rule updated',
					{ root: true }
				);
			} catch (exception) {
				if (exception.errors) {
					return commit(ERRORS, exception.errors);
				}

				logger.error(exception);
			}

			return;
		}

		try {
			await api.connections.contactSyncRules.post({
				connectionId: state.connection._id,
				token,
				ruleData
			});

			dispatch(CONTACT_SYNC_RULES_GET, state.connection._id);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'New contact sync rule added',
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[ERRORS_CLEAR]({ commit }) {
		commit(ERRORS, []);
	}
};
